.about-section {
  width: 70%;
  margin: auto;
  margin-top: 50px;
}

.profile-pic {
  width: 280px;
  height: 40vh;
  border-radius: 50%;
}
.github-logo,
.linkedin-logo {
  margin: 10px;
  color: black;
}
.linkedin-logo:hover,
.github-logo:hover {
  transition: 1.5s;
  transform: rotate(360deg);
}
.summary-text {
  font-size: 1rem;
  line-height: 30px;
  font-weight: 500;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}
@media (max-width: 768px) {
  .about-head {
    padding-top: 30px;
  }
  .profile-pic {
    width: 200px;
    height: 35vh;
    border-radius: 50%;
  }
}
