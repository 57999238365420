@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);

:root{
  --primary-white:#fff;
  --primary-orange:#f9ab00;
}


body{
  font-family: "Roboto Condensed", san-serif;
}
/* nav bar */
nav{
  z-index: 2;
  
}

nav a.nav-link {
  font-weight: 700;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}


nav ul li {
  text-transform: uppercase;
}
.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: #fff !important;
  color: var(--primary-white) !important;
  border-bottom: 0.1875rem solid #212529
}
.navbar-brand{
  color: #f9ab00 !important;
  color: var(--primary-orange) !important;
  font-weight: 700;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}


.donld-icon{
  padding-left: 0.625rem;
}


.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
  border-bottom: 0.1875rem solid #f9ab00;
  border-bottom: 0.1875rem solid var(--primary-orange) ;
}



/* hearder wrapper */

.header-wrapper{
  position: relative;
  height: 90vh;
  background: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrqITQdd5ZKGbiDWlKBTKjHrPLaXWoL2uMQ&usqp=CAU') no-repeat;
  background-size: cover;
  background-position: center;
}

.main-info{
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto Condensed", san-serif;
  z-index: 1;
}
.main-info h1{
  font-size: 40px !important;
  font-family: sans-serif;
  font-weight: 700;
  color: #f9ab00;
  text-transform: uppercase;
}
.image{
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin-bottom: 20px;
}


/* typed text */
.typed-text{
  font-size: 2rem;
  color: aqua;
}

@media(max-width:768px){
  .main-info h1{
    font-size: 1.7rem !important;
  }
  .typed-text{
    font-size: 1.3rem;
  }
  .navbar-brand{
    width: 7rem;
  }
}


.btn-main-offer{
  border: 1px solid #fff;
  border: 1px solid var(--primary-white);
  text-transform: uppercase;
  border-radius: 0;
  padding:0.625rem 0.75rem 0.75rem 0.75rem;
  text-decoration: none;
  font-weight: 700;
  color: white;
  margin: 2rem;
  letter-spacing: 2px;
}
.btn-main-offer:hover{
  text-decoration: none;
  background-color: #f9ab00;
  background-color: var(--primary-orange);
  color: #fff;
  transition: 0.2s ease-in-out;
}

/* particles */

.tsparticles-canvas-el{
  position: absolute;
  height: 100vh;
  width: 100vh;
  z-index: 1;
}

@media(max-width:768px){
  .particles-canvas{
    display: none;
  }
}










/* npm i react-typed */

/* npm i react-particles-js */

/* npm install bootstrap */
.about-section {
  width: 70%;
  margin: auto;
  margin-top: 50px;
}

.profile-pic {
  width: 280px;
  height: 40vh;
  border-radius: 50%;
}
.github-logo,
.linkedin-logo {
  margin: 10px;
  color: black;
}
.linkedin-logo:hover,
.github-logo:hover {
  transition: 1.5s;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.summary-text {
  font-size: 1rem;
  line-height: 30px;
  font-weight: 500;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}
@media (max-width: 768px) {
  .about-head {
    padding-top: 30px;
  }
  .profile-pic {
    width: 200px;
    height: 35vh;
    border-radius: 50%;
  }
}

.conatct-section {
  width: 100%;
  padding: 50px;
  background: rgb(153, 151, 151);
  text-align: center;
}
.conatct-div {
  width: 70%;
  grid-gap: 20px;
  gap: 20px;
}
.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.rounded-circle {
  color: #03203c;
  padding: 20px;
}
.contact_head {
  padding: 15px;
  color: #03203c;
}
.contact_h2_headings {
  color: #03203c;
}
.contact_h3_headings {
  padding: 10px;
  color: #03203c;
  font-size: 1rem;
  font-weight: 600;
}
.contact_h3_headings > a {
  text-decoration: none;
  color: #03203c;
}
.contact_h3_headings:hover{
    text-decoration: underline;
}

.scrollDiv {
  position: fixed;
  width: 50px;
  height: 50px;
  left: 95%;
  top: 90%;
  background: #03203c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 780px) {
  .scrollDiv {
    left: 88%;
    top: 90%;
  }
}

.project-image {
  height: 225px;
  width: 100%;
  display: block;
}
.project-section{
  background: whitesmoke;
}
.project-section>h1{
  text-align: center;
  padding: 20px;
  font-weight: 400;
}

.resume_div {
  width: 100%;
  height: 250px;
  margin-top: 50px;
  margin-bottom: 50px;
  background: url("https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=707&q=80");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-filter: opacity(20%) contrast(100%);
          filter: opacity(20%) contrast(100%);
  -webkit-filter: contrast(100%);
          filter: contrast(100%);
}
.resume_div > h1 {
  color: #dee9f7;
  text-shadow: 2px 2px #080808;
}
.resumeBtn {
  width: 200px;
  height: 50px;
  margin: 10px;
  background: #03203c;
  outline: none;
  border: 2px solid #dee9f7;
}
.resumeBtn > a {
  color: #dee9f7;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 1px;
}

@media screen and (max-width: 780px) {
  .resume_div > h1 {
    font-size: 20px;
    padding: 20px;
    color: #dee9f7;
    text-shadow: 2px 2px #080808;
  }
}

.skill-section {
  text-align: center;
  margin-top: 50px;
  position: static;
}
.skill-section>h1{
padding: 20px;
font-weight: 400;
}
.skills-logo {
  background: #46b2e0;
  width: 9.375rem;
  height: 9.375rem;
  margin: auto;
  margin-top: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills-image {
  height: 70% !important;
  width: 70% !important;
  border-radius: 50%;
}
.skills-image:hover {
  transition: 1.5s;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.skills-headings {
  font-weight: 500 !important;
  font-size: 20px !important;
  text-align: center;
  color: #0e80f1;
}
.btn-divs {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.skill-topics {
  font-weight: 500 !important;
  font-size: 15px !important;
  text-align: center;
}
.tech_stack_div{
  width: 60%;
  margin: auto;
  margin-bottom: 50px;
}
.t-s-heading{
  padding: 20px;
}

