.scrollDiv {
  position: fixed;
  width: 50px;
  height: 50px;
  left: 95%;
  top: 90%;
  background: #03203c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 780px) {
  .scrollDiv {
    left: 88%;
    top: 90%;
  }
}
