.resume_div {
  width: 100%;
  height: 250px;
  margin-top: 50px;
  margin-bottom: 50px;
  background: url("https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=707&q=80");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: opacity(20%) contrast(100%);
  filter: contrast(100%);
}
.resume_div > h1 {
  color: #dee9f7;
  text-shadow: 2px 2px #080808;
}
.resumeBtn {
  width: 200px;
  height: 50px;
  margin: 10px;
  background: #03203c;
  outline: none;
  border: 2px solid #dee9f7;
}
.resumeBtn > a {
  color: #dee9f7;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 1px;
}

@media screen and (max-width: 780px) {
  .resume_div > h1 {
    font-size: 20px;
    padding: 20px;
    color: #dee9f7;
    text-shadow: 2px 2px #080808;
  }
}
